<template>
    <div class="ProvinceCityArea">
        <el-form ref="formRef" :model="form" :rules="rules" label-width="100px">
            <el-form-item label="名称" prop="name">
                <el-input v-model="form.name" placeholder="请输入名称" maxlength="50" :disabled="disabled" clearable/>
            </el-form-item>
            <el-form-item label="类型" prop="type">
                <el-input v-model="form.type" placeholder="请输入类型" maxlength="1" :disabled="disabled" clearable/>
            </el-form-item>
            <el-form-item label="父类ID" prop="parentId">
                <el-input v-model="form.parentId" placeholder="请输入父类ID" maxlength="20" :disabled="disabled" clearable/>
            </el-form-item>
        </el-form>
    </div>
</template>


<script>
    import ProvinceCityAreaCardHelper from "./ProvinceCityAreaCardHelper.js";
    export default ProvinceCityAreaCardHelper;
</script>

<style scoped>
    .ProvinceCityArea{
        width: 100%;
    }
</style>
